import React from 'react';
import { formatMoney } from '../service/currency_converter';
import {ProductListing, ProductWishListing} from "../types/models/product";
import {RegionInfo} from "../hooks/region";
import Skeleton from "react-loading-skeleton";

interface PriceLabelProps {
  product: ProductListing | ProductWishListing;
  regionInfo: RegionInfo;
}

export default function PriceLabel({ product, regionInfo }: PriceLabelProps) {
  const {priceRange, price, priceCurrency} = product;
  const {countryCode, isLoading} = regionInfo;

  if (isLoading) {
    return <Skeleton />;
  }

  if (priceRange) {
    const [minPrice, maxPrice] = priceRange
    return <>
      {`${formatMoney(priceCurrency, minPrice[countryCode])} - ${formatMoney(priceCurrency, maxPrice[countryCode]).slice(3).trim()}`}
    </>;
  } else {
    return <>
      {formatMoney(priceCurrency, price[countryCode])}
    </>;
  }
}
import React from 'react';
import productListingStyles from '../../styles/product_listing.module.scss'
import Skeleton from "react-loading-skeleton";

export default function ProductListingCardSkeleton() {
  return <div className={`card mx-0 ${productListingStyles.productCard}`}>
    <Skeleton height={196} />

    <div className="card-body mt-5 mt-md-0">
      <div className={productListingStyles.productMarketplace}>
        <Skeleton height={16} />
      </div>

      <div className={productListingStyles.productName}>
        <Skeleton height={32} />
      </div>

      <p className={productListingStyles.productPrice}>
        <Skeleton height={32} />
      </p>
    </div>
  </div>
}
import Api from "../../service/api";
import React from "react";
import {ProductListing} from "../../types/models/product";
import ProductListingCard from "../product_listing_card";
import styles from '../../styles/home/recently_viewed_section.module.scss';
import ProductListingCardSkeleton from "../product/product_listing_card_skeleton";

export default function HomeRecentlyViewedSection() {
  const [recentlyViewedProducts, setRecentlyViewedProducts] = React.useState<ProductListing[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    Api.product.recentlyViewed()
      .then(res => {
        const products = res.data.products;
        setRecentlyViewedProducts(products);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div className={styles.row}>
      {[...new Array(15)].map((_, index) => (
        <div className={styles.col} key={index}>
          <ProductListingCardSkeleton />
        </div>
      ))}
    </div>;
  } else {
    return <div className={styles.row}>
      {recentlyViewedProducts.map((product, index) => (
        <div className={styles.col} key={index}>
          <ProductListingCard
            product={product}
          />
        </div>
      ))}
    </div>;
  }
}
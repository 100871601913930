import React, {HTMLProps} from 'react'
import useResizeObserver from '@react-hook/resize-observer';

interface MultilineEllipsis extends HTMLProps<HTMLDivElement> {
	text: string;
	lines: number;
}

export default function MultilineEllipsis({ text, lines, ...props }: MultilineEllipsis) {
	const resizeObserverRef = React.useRef<ResizeObserver>();
	const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
	const containerRef = React.useRef<HTMLElement | null>(null);

	const [containerWidth, setContainerWidth] = React.useState<number | null>(null);
	const [font, setFont] = React.useState<string | null>(null);

	useResizeObserver(containerRef, (entry) => {
		// Firefox implements `contentBoxSize` as a single content rect, rather than an array
		const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
		// console.log('contentBoxSize', contentBoxSize);
		setContainerWidth(contentBoxSize.inlineSize);
	});

	const getTextWidth = React.useCallback((text: string, font: string) => {
		// re-use canvas object for better performance
	    const canvas = canvasRef.current || (canvasRef.current = document.createElement("canvas"))
	    const context = canvas.getContext("2d");
	    if (context) {
				context.font = font;
				const metrics = context.measureText(text);
				return metrics.width;
			} else {
	    	return 0;
			}
	}, [])

	const containerRefSetter = React.useCallback((ref: HTMLDivElement) => {
		if (!ref) return
		
		const computedStyle = getComputedStyle(ref)
		const newFont = [computedStyle.fontWeight, computedStyle.fontSize, computedStyle.fontFamily].join(' ')
		setFont(newFont)

		if (resizeObserverRef.current) {
			resizeObserverRef.current.observe(ref)
		}
		containerRef.current = ref
	}, [])

	// when resize or font change or lines change or text change
	const outputText = React.useMemo(() => {
		if (font === null || containerWidth === null) {
			return
		}

		const words = text.split(/\b|(?=\W)/)
		// console.log('words', words)
		const ellipsisWidth = getTextWidth('…', font)
		let line = 0
		let wordLastStop = 1

		while (line < lines) {
			let lineStart = 0
			if (line > 0) {
				lineStart = wordLastStop
			}
			let i = lineStart + 1
			let isLineFull = false
			for (; i <= words.length; i++) {
				const currentLine = words.slice(lineStart, i).join('')
				const currentLineWidth = getTextWidth(currentLine, font)
				// console.log('currentLine', currentLine, currentLineWidth, ellipsisWidth, currentLineWidth + ellipsisWidth,  containerWidth)
				if (currentLineWidth + ellipsisWidth >= containerWidth) {
					wordLastStop = i - 1
					line++
					isLineFull = true
					break
				}
			}

			if (!isLineFull && i >= words.length) {
				// to handle when words has reached the end and line is full causing the original text to be shown
				wordLastStop = words.length
				break
			}
		}

		if (wordLastStop === 0) {
			// handle 1 word by ellipsing the letters
			const word = words[0]
			for (let i = word.length - 1; i >= 0; i--) {
				const currentLine = word.slice(0, i)
				const currentLineWidth = getTextWidth(word, font)
				if (currentLineWidth + ellipsisWidth < containerWidth) {
					return currentLine + '…'
				}
			}
			return '…'
		} else if (wordLastStop === words.length) {
			// word was not truncated
			return text
		} else {
			const output = words.slice(0, wordLastStop).join('')
			// console.log('output', output, wordLastStop, words.length)
			return output + '…'
		}
	}, [containerWidth, font, lines, text])

	return <div {...props} ref={containerRefSetter}>{outputText}</div>
}
import React from 'react';
import urljoin from 'url-join';
import styles from '../../styles/home_group_products_category.module.scss';
import UnderlineNav from '../underline_nav';
import Api from '../../service/api';
import HorizontalScrollingProducts from '../horizontal_scrolling_products';
import {HomeProductGroup} from "../../types/models/home_product_group";
import {ProductListing} from "../../types/models/product";

interface HomeGroupProductsCategoryProps {
  homeProductGroup: HomeProductGroup<'category'>;
}

export default function HomeGroupProductsCategory({homeProductGroup}: HomeGroupProductsCategoryProps) {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [groupProducts, setGroupProducts] = React.useState<Record<string, ProductListing[]>>({
    '0': []
  });

  React.useEffect(() => {
    const subCategory = homeProductGroup.metadata.subCategories[activeIndex]
    if (!groupProducts[subCategory.id]) {
      Api.homeProductGroups.getHomepageProductsByCategory(subCategory.id, homeProductGroup.limit)
        .then(res => {
          setGroupProducts({
            ...groupProducts,
            [subCategory.id]: res.data.products,
          })
        })
        .catch(error => {
          console.error('failed to load home product group category', subCategory.id, error);
        });
    }
  }, [activeIndex]);

  const subCategoryNames = homeProductGroup.metadata.subCategories.map(c => c.name)
  let subCategoryId = 0
  if (homeProductGroup) {
    subCategoryId = homeProductGroup.metadata.subCategories[activeIndex].id
  }

  const products = groupProducts[subCategoryId] || []
  const imageEndpoint = process.env.NEXT_PUBLIC_IMAGES_ENDPOINT
  let headerStyle
  if (homeProductGroup.headerImagePath && imageEndpoint) {
    headerStyle = {
      backgroundImage: `url(${urljoin(imageEndpoint, homeProductGroup.headerImagePath)})`
    }
  }

  return <>
    <div className={`position-relative bg-primary-gradient ${styles.header}`}>
      <div 
        className={styles.headerBackground} 
        style={headerStyle}
      >
        <h4 className="mb-0 h-100">{homeProductGroup.metadata.categoryName}</h4>
      </div>
    </div>
    <UnderlineNav
      navItems={subCategoryNames}
      activeIndex={activeIndex}
      onChange={setActiveIndex}
    />

    {products.length === 0 ? (
      <div className={styles.noProductPlacehold}>
        No Products Yet
      </div>
    ) : (
      <HorizontalScrollingProducts
        products={products}
      />
    )}
  </>
}
import {Product} from "../types/models/product";

interface SourceLabelProps {
  product: Pick<Product, 'sourceMarketPlace' | 'merchant'>;
}

export default function SourceLabel({ product }: SourceLabelProps) {
	let sourceLabel = product.sourceMarketPlace;
  if (sourceLabel === 'local' && product.merchant) {
    sourceLabel = product.merchant.name;
  }

  return <>
    {sourceLabel}
  </>;
}
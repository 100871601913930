import React from "react";
import styles from '../../styles/carousel.module.scss';

interface Props {
	children: React.ReactNode;
}

export default function CarouselIcon({children}: Props) {
	return <div className={`d-inline-block ${styles.icon}`}>
		{children}
	</div>;
}